import React from "react";

import PageHead from "@Components/PageHead";
import { useStaticQuery, graphql } from "gatsby";

function careers() {
  const data = useStaticQuery(PageQuery);
  return (
    <>
      <PageHead backgroundImg={data.careerHero.childImageSharp.fluid}>
        <h2 className="head-title">
          <span>Careers</span>
        </h2>
      </PageHead>
    </>
  );
}

export default careers;

export const PageQuery = graphql`
  query {
    careerHero: file(relativePath: { eq: "careerHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
